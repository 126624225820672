$primaryWhite: #F8F8F8FF;
$primaryGrey: #4C4C4CFF;

input {
  margin-top: 10rem;
}

input,
textarea {
  -webkit-font-smoothing: antialiased;
  font: inherit;
  color: $primaryWhite;
}

.input {
  background: none;
  font-size: 72px;
  width: calc(100vw - 4rem);
  border: none;
  outline: none;
  text-align: center;
  padding: 0;

  .input::placeholder {
    color: $primaryGrey;
  }
}

